import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SalaryViewModel } from '../../../models/index';
/* import { SalaryApiService } from '../../../api/index'; */

@Component({
  selector: 'app-archive-salary-person',
  templateUrl: './archive-salary-person.component.html',
  styleUrls: ['./archive-salary-person.component.less']
})
export class ArchiveSalaryPersonComponent implements OnInit {
  /* salaryList: SalaryViewModel[] = []; */
  @Input("data") selectedModel: SalaryViewModel;
  showDialog2: boolean = false;
  currentItem: any;

  constructor(
    /* private sallaryApi: SalaryApiService, */
    private datePipe: DatePipe) { }

  ngOnInit() {
    /* this.getSalaryArchive(); */
  }

  /* getSalaryArchive = () => {
    let salaryQuery: SalaryArchiveViewFilterModels = {
      orderNum: '',
      start: new Date(2000, 1, 1),
      end: new Date(2025, 1, 1)
    };
    this.sallaryApi.getArchiveSalary(salaryQuery).subscribe(s => {
      console.log('getArchiveSalary');
      console.log(s);
    });
  } */

  transformDate = (date): string =>
    this.datePipe.transform(date, 'dd.MM.yyyy HH:mm');

  getProtezCount = (item: any) => {
    let protezCount = 0;

    if (item.salaryJobProtezList) {
      item.salaryJobProtezList.forEach(element => {
        protezCount = protezCount + element.cnt;
      });
    }

    return protezCount;
  }

  getSeleryWorkSum = (item: any) => {
    let sum = 0;

    if (item.salaryJobProtezList) {
      item.salaryJobProtezList.forEach(element => {
        sum = sum + element.cnt * element.price;
      });
    }

    return sum;
  }

  salryRowClick = (item: any) => {
    this.showDialog2 = true;
    this.currentItem = { ...item };
    console.log(this.currentItem);
  }

  closeDialog2 = () => {
    this.currentItem = {};
    this.showDialog2 = false;
  }

}
