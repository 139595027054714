import { Component, OnInit, Output, EventEmitter, NgZone, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NgLog, Camera, HashTable, fullName } from '../../../Helper';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { SalaryViewModel, BaseViewGridModel, SalaryJobViewModel } from '../../../models';
import { ControlType, IGridOptions } from '../grid/grid.component';
import { DatePipe } from '@angular/common';

@NgLog()
@Component({
  selector: 'salary-person',
  templateUrl: './salary-person.component.html',
  providers: [DatePipe]
})
export class SalaryPersonComponent implements OnInit {
  @Input("data") selectedModel: SalaryViewModel;
  @Output() salaryConfirm = new EventEmitter<number>();
 /*  fieldSalaryOptions: HashTable<IGridOptions>; */
  showDialog2: boolean = false;
  currentItem: any;

  constructor(private datePipe: DatePipe) {
   /*  this.fieldSalaryOptions = {
      createDate: {
        title: 'Дата',
        order: false,
        fieldType: {
          value: (item: any) => this.datePipe.transform(item.createDate, 'dd.MM.yyyy'),
          type: ControlType.customValue
        }
      },
      regionName: {
        title: 'Клиника',
        order: false
      },
      sum: {
        title: 'зп за работу',
        order: false
      },
      jobName: {
        title: 'Номер заказа',
        order: false
      }
    }; */
    this.selectedModel = {
      salaryWorks: {} as BaseViewGridModel<SalaryJobViewModel>
    } as SalaryViewModel;
  }



  ngOnInit() {

  }

  transformDate = (date): string =>
    this.datePipe.transform(date, 'dd.MM.yyyy HH:mm');

  getSeleryWorkSum = (item: any) => {
    let sum = 0;

    if (item.salaryJobProtezList) {
      item.salaryJobProtezList.forEach(element => {
        sum = sum + element.cnt * element.price;
      });
    }

    return sum;
  }

  getProtezCount = (item: any) => {
    let protezCount = 0;

    if (item.salaryJobProtezList) {
      item.salaryJobProtezList.forEach(element => {
        protezCount = protezCount + element.cnt;
      });
    }

    return protezCount;
  }

  salryRowClick = (item: any) => {
    /*     if (item.salaryJobProtezList.length == 1)
          return; */
    this.showDialog2 = true;
    this.currentItem = { ...item };
    console.log(this.currentItem);
  }

  closeDialog2 = () => {
    this.currentItem = {};
    this.showDialog2 = false;
  }

  onSalaryConfirm = (id: number) => {
    this.salaryConfirm.emit(id);
  }
}
