﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { RoleType } from "./Dental-DataModel-CustomTypes-RoleType";
import { BaseViewGridModel } from "./Dental-WebCore-Models-BaseViewGridModel";
import { SalaryJobViewModel } from "./Dental-WebCore-Models-SalaryJobViewModel";

export class SalaryViewModel   {
    
    id: number;
    roleType: RoleType;
    firstName: string;
    lastName: string;
    middleName: string;
    workTypeId: number;
    workType: string;
    value: number;
    percentage: number;
    isAccrued: boolean;
    isUserConfirmed: boolean;
    canAccrued: boolean;
    accruedDate?: any;
    startDate: any;
    endDate: any;
    dateCreation?: any;
    salaryWorks: BaseViewGridModel<SalaryJobViewModel>;
}




  
